<template>
    <UpdateAssessment :isShown="isShown" :closePanel="closePanel" />
    <ConfirmDeleteAssessment :closeConfirm="closeConfirm" :isShown="isShownDelete" :id="assessement._id" />
    <div class="top-assessement w-full">
        <div class="relative flex flex-col items-start justify-between h-full w-full px-3 py-3 bg-white">
            <div class="topAssess w-full">
                <div class="flex relative justify-between">
                    <div class="flex gap-0 border-[#2196f3] pb-1 flex-col justify-center items-start w-full">
                        <h2
                            class="title-question w-full text-lg font-semibold text-left relative pb-2 mb-2 after:content-('') after:absolute after:bottom-0 after:left-0 after:w-full after:h-px after:bg-NeonBlue"
                        >
                            {{ assessement?.name }}
                        </h2>
                        <div class="flex justify-center gap-4 items-center mb-5">
                            <span class="text-[0.9rem] text-NeonBlue font-normal">{{ assessement?.category }}</span>
                            <span class="text-[0.9rem] text-NeonBlue font-normal">|</span>
                            <span class="text-[0.9rem] text-NeonBlue font-normal"
                                >{{ assessement?.questions_nbr > 25 ? parseInt((20 * 35) / 60) : parseInt((assessement?.questions_nbr * 35) / 60) }} '</span
                            >
                            <span class="text-[0.9rem] text-NeonBlue font-normal">|</span>
                            <span class="text-[0.9rem] text-NeonBlue font-normal"> {{ !assessement?.UserId ? "Go Platform" : "My library" }} </span>
                        </div>
                    </div>
                    <div class="absolute top-0 right-0">
                        <font-awesome-icon
                            v-if="assessement?.UserId"
                            @click.stop="isOpen = !isOpen"
                            class="cursor-pointer rounded-full w-5 h-5 hover:bg-[#E0E0E0] p-1 rotate-90"
                            :icon="['fas', 'ellipsis']"
                        />
                    </div>
                    <div v-if="isOpen" ref="container" class="absolute -right-12 p-2 bottom-[20%] rounded-md w-25 flex bg-white border border-gray-200 flex-col gap-2 z-10">
                        <font-awesome-icon @click="openUpdate(assessement._id)" class="cursor-pointer hover:bg-[#E0E0E0] rounded-md p-1" :icon="['far', 'pen-to-square']" />
                        <font-awesome-icon @click="isShownDelete = true" class="cursor-pointer rounded-md hover:bg-[#E0E0E0] p-1" :icon="['far', 'trash-can']" />
                    </div>
                </div>
                <p class="text-sm leading-4 font-light pb-4 text-left">{{ getFirstPhrase(assessement?.description_test) }}...</p>
            </div>
            <div class="w-full flex justify-end gap-2 items-center btnWrapper relative">
                <!-- <button class="details" @click="$emit('openPreview')"><font-awesome-icon :icon="['far', 'eye']" /></button> -->
                <button
                    class="bg-black/5 text-gray-700 hover:bg-black/10 inline-flex items-center justify-center text-m rounded min-h-[30px] px-4 py-[10px] font-semibold"
                    @click="$emit('openPreview')"
                >
                    {{ $t("Details") }}
                </button>
                <div
                    v-if="!verifyAddedQuestion"
                    class="bg-[#2196f3] text-white hover:bg-[#1976d2] inline-flex shadow items-center justify-center text-m rounded w-[4rem] min-h-[30px] px-4 py-[10px] font-semibold flex items-center justify-center relative cursor-pointer"
                    @click="addAssessment(assessement)"
                >
                    <div
                        v-if="assessement?.rating !== 'essential' && !this.Store.premium"
                        class="absolute right-[-10px] top-[-15px] text-[#FFC300] w-[30px] h-[30px] rounded-[50%] text-xl flex justify-center items-center bg-white"
                        style="border: 3px solid #ffc300"
                    >
                        <font-awesome-icon :icon="['far', 'gem']" />
                    </div>
                    <!-- <button class="add-now" @click="addAssessment(assessement)"> -->
                    {{ $t("Add now") }}
                    <!-- </button> -->
                </div>
                <button
                    v-else
                    @click="removeAssessment(assessement)"
                    class="bg-red-600 text-gray-700 hover:bg-red-700 inline-flex shadow items-center justify-center w-[4rem] text-m rounded min-h-[30px] px-5 py-[13px] font-semibold"
                >
                    <font-awesome-icon class="text-white" :icon="['fas', 'xmark']" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "@/store";
import UpdateAssessment from "@/components/UpdateAssessment";
import ConfirmDeleteAssessment from "@/components/ConfirmDeleteAssessment";

export default {
    name: "TopAssessement",
    props: ["assessement", "addAssessment", "placeholders", "removeAssessment"],
    data() {
        return {
            imagePath: "",
            isOpen: false,
            isShown: false,
            isShownDelete: false,
        };
    },
    components: {
        UpdateAssessment,
        ConfirmDeleteAssessment,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    computed: {
        verifyAddedQuestion() {
            return !!this.placeholders.find((placeholder) => placeholder?.assessment?.name === this.assessement?.name);
        },
    },
    methods: {
        closeConfirm() {
            this.isShownDelete = false;
        },
        openUpdate(id) {
            this.isShown = true;
            this.Store.assessmentId = id;
        },
        closePanel() {
            this.isShown = false;
        },
        getFirstPhrase(description) {
            // Split the string into an array of words
            let words = description?.split(/\s+/);

            // Extract the first 200 words
            let first200Words = words?.slice(0, 15)?.join(" ");
            return first200Words;
        },
        // imageExists(name) {
        //     try {
        //         if (name?.toLowerCase().includes("kpmg")) {
        //             // Use a different image path if 'name' includes 'special'
        //             // console.log("KPMG");
        //             this.imagePath = require("@/assets/assessmentsLogos/KPMG.svg");
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("workforce")) {
        //             // Use a different image path if 'name' includes 'special'
        //             // console.log("KPMG");
        //             this.imagePath = require("@/assets/assessmentsLogos/workforce.png");
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("bbc")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/BBC.png`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("dragnet")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/dragnet.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("nnpc")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/NNPC.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("nvidia")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Nvidia.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("oracle")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/oracle.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("pwc")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/PWC.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("gtco")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/GTCO.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("zenithbank")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/ZenithBank.svg`);
        //             return true;
        //         }

        //         if (name?.toLowerCase().includes("at&t")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/AT&T.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("amazon")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/amazon.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("aws")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/amazon.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("accenture")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Accenture.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("adobe")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Adobe.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("adp")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/ADP.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("aig")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/AIG.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("alcatel")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/alcatel.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("amdocs")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/amdocs.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("bayer")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Bayer.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("blackrock")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/BlackRock.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("capgemini")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Capgemini.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("cisco")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Cisco.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("cognizant")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Cognizant.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("cyient")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Cyient.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("dell")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Dell.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("deloitte")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Deloitte.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("ericsson")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Ericsson.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("sutherland")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/eversheds-sutherland.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("eversheds-sutherland")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/eversheds-sutherland.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("expedia")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Expedia.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("google")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Google.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("hackerrank")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/hackerrank.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("hcl")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/HCL_Technologies.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("hp")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/hp.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("hsbc")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/hsbc.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("huawei")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Huawei.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("ibm")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/ibm.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("infosys")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Infosys.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("intel")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Intel.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("mindtree")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/mindtree.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("quest")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/QuestGlobal.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("global")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/QuestGlobal.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("reuters")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/reuters.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("sap")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/sap.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("sony")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Sony.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("visa")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Visa.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("vmware")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/VMware.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("wipro")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/Wipro.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("zte")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/zte.svg`);
        //             return true;
        //         }
        //         if (name?.toLowerCase().includes("mckinsey")) {
        //             this.imagePath = require(`@/assets/assessmentsLogos/McKinsey.png`);
        //             return true;
        //         }
        //         // Use a dynamic import to check if the image file exists
        //         this.imagePath = require(`@/assets/assessmentsLogos/aws.svg`);
        //         return true;
        //     } catch (error) {
        //         return false;
        //     }
        // },
        formatName(name) {
            let newName = name.replace("-", " ");
            return newName;
        },
    },
};
</script>

<style lang="scss" scoped>
.top-assessement {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #fff;
    border-radius: 10px;
    // box-shadow: 0px 0px 10px 2px rgba(195, 195, 195, 0.8);
    padding: 0.2rem;
    gap: 2rem;
    padding-bottom: 1rem;
    // height: 300px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow:
        20px 20px 60px #bebebe33,
        -20px -20px 60px #ffffff3a;
    transition: all 0.3s ease;
    height: 300px;
}

.topAssess {
    // display: grid;
    // grid-template-rows: 1fr 2.5fr 1fr;
}

.btnWrapper {
    // margin-top: 10%;

    .add-now {
        padding: 0.25rem;
        border-radius: 5px;
        color: #fff;
        background: #2196f3;
        border: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        width: 27%;
        place-self: center;
        height: 40px;
        border-radius: 10px;
        transition: all 0.3s ease;
    }

    .details {
        padding: 0.25rem;
        border-radius: 5px;
        color: #2196f3;
        background: #fff;
        border: 1px solid #2196f3;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        width: 30%;
        place-self: center;
        height: 40px;
        border-radius: 10px;
        transition: all 0.3s ease;
    }
}

.time {
    color: #000000b6;
}

.title {
    font-size: 100%;
    font-weight: 600;
    text-transform: capitalize;
    text-align: left;
    line-height: 1.2;
}

.assessDesc {
    font-size: 80%;
    font-weight: 400;

    margin-bottom: 15%;
    text-align: left;
    line-height: 1.2;
}
</style>
