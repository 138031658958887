<template>
    <div v-if="isShown" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-10 z-[100] flex items-center justify-center" style="z-index: 1000">
        <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgc" />

        <div class="w-[90%] h-[95%] shadow-card bg-white rounded-md p-2 flex gap-4" style="z-index: 5">
            <div v-if="!isFetching" class="w-[100%] p-10">
                <form @submit.prevent="submitForm" class="h-[95%] mb-5">
                    <div class="w-full flex items-center justify-content">
                        <div class="w-full flex text-[#A9AAAE] py-4 items-center gap-4">
                            <h1 style="font-size: 30px; color: #2196f3">Edit Assessment</h1>
                        </div>
                    </div>
                    <hr class="h-[5px] bg-[#F5F8FF]" />

                    <div class="mt-[1%] h-[85%] overflow-y-scroll p-2">
                        <div class="flex mb-[2rem] items-center justify-between">
                            <h1 class="text-[20px] text-slate-700 font-[600]">Information</h1>
                        </div>
                        <div class="relative mb-[3%]">
                            <div class="grid grid-cols-2 gap-4">
                                <div class="grid grid-cols-1 gap-4">
                                    <div class="flex flex-col items-start gap-1 w-full">
                                        <label>Assessment Name<span class="required"> *</span></label>
                                        <input
                                            required
                                            v-model="assessment.name"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            type="text"
                                            placeholder="Enter assessment name"
                                            id="testTitle"
                                            autocomplete="false"
                                            style=""
                                        />
                                    </div>
                                    <div class="flex flex-col items-start gap-1 w-full">
                                        <label>Assessment Category<span class="required"> *</span></label>
                                        <div
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                            <select class="bg-gray-50" v-model="assessment.category" required>
                                                <option value="" selected disabled>Select a category</option>
                                                <option v-for="(category, index) in categoriesList" :key="index" :value="category">
                                                    {{ category }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 gap-4">
                                    <div class="flex flex-col items-start gap-1 w-full">
                                        <label>Assessment Description<span class="required"> *</span></label>
                                        <textarea
                                            style="resize: none"
                                            id=""
                                            cols="30"
                                            rows="5"
                                            v-model="assessment.description_test"
                                            placeholder="Enter assessment description"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            <hr class="h-[5px] bg-[#F5F8FF] mt-[3rem] mb-[2rem]" />

                            <div class="flex items-center justify-between">
                                <h1 class="text-[20px] leading-none mb-[2rem] text-slate-700 font-[600]">Questions:</h1>
                            </div>

                            <div
                                v-for="(question, questionIndex) in assessment?.questions_list"
                                :key="questionIndex"
                                class="flex border-b-[5px] border-[#F5F8FF] flex-col mb-5 pb-5 items-start w-full gap-4"
                            >
                                <label class="w-full text-[#2196f3] text-start font-bold text-lg">Question {{ questionIndex + 1 }}</label>
                                <div class="flex mb-5 items-start w-full gap-4">
                                    <div class="flex w-1/2 flex-col justify-start items-start gap-5">
                                        <div class="flex flex-col items-start gap-1 w-full">
                                            <label>Question<span class="required"> *</span></label>
                                            <input
                                                required
                                                v-model="question.question"
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                type="text"
                                                placeholder="Enter the question"
                                                id="testTitle"
                                                autocomplete="false"
                                                style=""
                                            />
                                        </div>
                                        <div class="flex flex-col items-start gap-1 w-full">
                                            <label>Description</label>
                                            <textarea
                                                id=""
                                                cols="30"
                                                rows="4"
                                                style="resize: none"
                                                v-model="question.description"
                                                placeholder="Enter the question description"
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            ></textarea>
                                        </div>
                                        <div class="flex flex-col items-start gap-1 w-full">
                                            <label>Code</label>
                                            <input
                                                v-model="question.code"
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                type="text"
                                                placeholder="Enter the question code"
                                                id="category"
                                                autocomplete="false"
                                                style=""
                                            />
                                        </div>
                                        <div class="flex flex-col items-start gap-1 w-full">
                                            <label>Answer:</label>
                                            <div
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            >
                                                <select class="bg-gray-50" v-model="assessment.answers[questionIndex + 1]" @change="updateAnswer($event, questionIndex)" required>
                                                    <option value="" selected disabled>Select an answer</option>
                                                    <option v-for="(value, key) in question?.options" :key="key" :value="key">
                                                        {{ convertToAlphabet(key) }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex flex-col w-1/2 justify-start items-start gap-4">
                                        <div class="flex flex-col items-start gap-1 w-full">
                                            <label>Options:</label>
                                            <div class="p-3 h-[19.5rem] overflow-y-scroll w-full bg-[#F4FAFF] rounded-lg">
                                                <div class="flex mb-1 w-full relative items-center gap-1" v-for="(value, key) in question?.options" :key="key">
                                                    <span class="rounded-[7px] h-12 w-12 bg-white flex items-center justify-center border border-[1px] border-[#d8e2ee]">
                                                        {{ convertToAlphabet(key) }}
                                                    </span>
                                                    <input
                                                        class="bg-gray-50 h-12 w-12 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        v-model="question.options[key]"
                                                        type="text"
                                                        placeholder="Option text"
                                                        required
                                                    />
                                                    <button
                                                        class="absolute flex items-center justify-center text-red-500 right-[2%]"
                                                        @click="removeOption(key)"
                                                        v-if="Object.keys(question?.options)?.length - 1 == Object.keys(question?.options).indexOf(key) && Object.keys(question?.options)?.length > 4"
                                                    >
                                                        <font-awesome-icon class="w-6 h-6 hover:bg-red-200 rounded-full" :icon="['far', 'circle-xmark']" />
                                                    </button>
                                                </div>
                                                <button
                                                    class="rounded-[7px] hover:opacity-80 bg-blue-500 text-white font-bold h-12 w-12 flex items-center justify-center"
                                                    @click="addOption(questionIndex)"
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="questionIndex == assessment?.questions_list?.length - 1" class="flex items-center justify-between w-full">
                                    <ButtonComponent v-if="assessment?.questions_list?.length > 15" intent="secondary" @click="removeQuestion(questionIndex)"> Remove Question </ButtonComponent>
                                    <div v-else class="h-12 mt-3 w-[40%]"></div>
                                    <ButtonComponent intent="primary" @click="addQuestion">Add Question + </ButtonComponent>
                                </div>
                            </div>

                            <!--    <div class="flex-auto text-heading-xs color-secondary"
                                style="margin-top:30px; font-weight: bold;"> Questions </div>

                            <div class="data-table--main mt-24">
                                <div class="data-table--container">
                                    <table class="mt-5 w-full border-collapse border border-gray-300">
                                        <thead>
                                            <tr>
                                                <th class="border border-gray-300 px-4 py-2">Question</th>
                                                <th class="border border-gray-300 px-4 py-2">Description</th>
                                                <th class="border border-gray-300 px-4 py-2">Options</th>
                                                <th class="border border-gray-300 px-4 py-2">Answer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(question, index) in assessment.questions_list" :key="index">
                                                <td class="border border-gray-300 px-4 py-2">{{ question.question }}
                                                </td>
                                                <td class="border border-gray-300 px-4 py-2">{{ question.description }}
                                                </td>
                                                <td class="border border-gray-300 px-4 py-2">
                                                    <span v-for="(option, key) in question.options" :key="key">{{
            convertToAlphabet(key) }}: {{ option }}<br></span>
                                                </td>
                                                <td class="border border-gray-300 px-4 py-2">{{
            convertToAlphabet(assessment.answers[index + 1]) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
-->
                        </div>
                    </div>
                    <div class="flex items-center mt-5 gap-3 justify-end w-full">
                        <button
                            @click="
                                () => {
                                    closePanel();
                                }
                            "
                            class="w-[20%] h-15 py-3 rounded-lg border-none bg-black/5 font-medium text-gray-700 hover:bg-black/10"
                        >
                            Cancel
                        </button>
                        <button class="w-[20%] h-15 py-3 rounded-lg border-none bg-[#2196f3] font-medium text-white shadow-md hover:opacity-80" type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div v-else class="w-full h-full flex items-center justify-center">
                <LoaderComponentBlue />
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from "./ReusableComponents/ButtonComponent.vue";
import LoaderComponentBlue from "../components/LoaderComponentBlue.vue";

//import FloatingLabel from "./ReusableComponents/FloatingLabel.vue";
//import SelectComponent2 from "@/components/ReusableComponents/SelectComponent2.vue";
import { useStore } from "../store/index";
import ToastNotification from "../components/ToastNotification.vue";
import axios from "axios";
//import LoaderComponent from "../components/LoaderComponent.vue";

export default {
    name: "UpdateAssessment",
    components: {
        ButtonComponent,
        // FloatingLabel,
        // SelectComponent2,
        // LoaderComponent,
        ToastNotification,
        LoaderComponentBlue,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    mounted() {},
    props: ["closePanel", "isShown"],
    watch: {
        isShown(newVal) {
            if (newVal) {
                this.getAssessment();
            }
        },
    },
    data() {
        return {
            categoriesList: ["Psychometrics", "Hard Skills", "Soft Skills"],
            currentQuestion: {
                question: "",
                description: "",
                options: {
                    1: "",
                    2: "",
                    3: "",
                    4: "",
                },
            },
            currentAnswer: "",
            assessment: {
                name: "",
                category: "",
                description_test: "",
                questions_list: [
                    {
                        question_number: 1,
                        question: "",
                        description: "",
                        code: "",
                        options: {
                            1: "",
                            2: "",
                            3: "",
                            4: "",
                        },
                    },
                ],
                answers: {},
            },
            interpretations: [
                { range: [0, 33], title: "", description: "" },
                { range: [34, 66], title: "", description: "" },
                { range: [67, 100], title: "", description: "" },
            ],
            isLoading: false,
            isVisible: false,
            isFetching: true,
            isOpen: false,
            message: "",
            bgColor: "",
        };
    },
    methods: {
        getAssessment() {
            const assessmentId = this.Store.assessmentId;
            console.log("this assessment Id: " + assessmentId);
            axios
                .get(`https://server.go-platform.com/uploadAssessment/${assessmentId}`)
                .then((response) => {
                    this.assessment = response.data.assessment;
                    console.log("This assessment", this.assessment);
                })
                // .then(() => this.getInterpretation())
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => (this.isFetching = false));
        },
        clearQuestion() {
            this.currentQuestion = {
                question: "",
                description: "",
                options: {
                    1: "",
                    2: "",
                    3: "",
                    4: "",
                },
            };
        },
        addQuestionToTable() {
            // Add current question to the questions list
            const newQuestion = { ...this.currentQuestion };
            this.assessment.questions_list.push(newQuestion);

            // Add the selected answer to the answers object
            const questionNumber = this.assessment.questions_list.length;
            this.assessment.answers[questionNumber] = this.currentAnswer;

            // Clear the current question inputs
            this.currentQuestion = {
                question: "",
                description: "",
                options: {
                    1: "",
                    2: "",
                    3: "",
                    4: "",
                },
            };
            this.currentAnswer = ""; // Clear the selected answer
        },

        async createInterpretation() {
            const interpretationData = {
                assessmentName: this.assessment.name,
                interpretations: this.interpretations,
            };
            axios
                .post("https://server.go-platform.com/interceptions/uploadInterception", interpretationData)
                .then((response) => console.log("Here is the interpretation", response.data))
                .catch((error) => console.error(error));
        },
        addInterpretation() {
            this.interpretations.push({ range: [0, 0], title: "", description: "" });
        },
        removeInterpretation(index) {
            this.interpretations.splice(index, 1);
        },
        removeQuestion(index) {
            this.assessment.questions_list.splice(index, 1);
        },
        async submitForm() {
            this.isLoading = true;
            const assessmentId = this.Store.assessmentId;

            try {
                await axios.put(`https://server.go-platform.com/uploadAssessment/${assessmentId}`, this.assessment);

                this.isVisible = true;
                this.message = "Assessment updated successfully!";
                this.bgc = "success";
            } catch (error) {
                console.error("Request failed", error);
                this.isVisible = true;
                this.message = "Failed to update the assessment.";
                this.bgc = "error";
            } finally {
                this.isLoading = false;
            }
        },
        toggleType() {
            this.isOpen = !this.isOpen;
        },
        updateAnswer(event, questionIndex) {
            const selectedValue = Number(event.target.value); // Convert the selected value to a number
            this.assessment.answers = {
                ...this.assessment.answers,
                [(questionIndex + 1).toString()]: selectedValue, // Update the answers object reactively
            };
        },
        addQuestion() {
            this.assessment.questions_list.push({
                question_number: this.assessment.questions_list.length + 1,
                question: "",
                options: {
                    1: "",
                    2: "",
                    3: "",
                    4: "",
                },
            });
        },
        addOption(questionIndex) {
            const currentOptions = this.assessment.questions_list[questionIndex].options;
            const nextOptionNumber = (Object.keys(currentOptions).length + 1).toString(); // Ensure the key is a string
            // Directly set the new option
            this.assessment.questions_list[questionIndex].options[nextOptionNumber] = "";
        },
        removeOption(questionIndex, optionKey) {
            const currentOptions = this.assessment.questions_list[questionIndex].options;
            if (Object.keys(currentOptions).length > 4) {
                // Directly delete the option
                delete this.assessment.questions_list[questionIndex].options[optionKey];
            }
        },
        convertToAlphabet(key) {
            const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            return alphabet[parseInt(key) - 1]; // Convert numeric key to alphabet (1 -> A, 2 -> B, etc.)
        },
    },
};
</script>

<style scoped lang="scss">
.select-container {
    width: 100%;
    padding: 0.7rem 0.1rem;
    border-radius: 7px;
    background: #fff;
    border: 1px solid #d8e2ee;
    box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
    display: flex;
    justify-content: start;
}

select {
    width: 97%;
    outline: none;
    cursor: pointer;
    height: 100%;
}

.radio-container {
    margin-top: 5%;
    margin-bottom: 10px;
    /* Adjust margin as needed */
    cursor: pointer;
    /* Change cursor to pointer */
    border: 1px solid #ccc;
    /* Border color */
    padding: 10px 10px;
    /* Adjust padding as needed */
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

/* Hover effect */
.radio-container:hover {
    background-color: #f0f0f0;
    /* Background color on hover */
}

/* Style for checked radio button container */
.checked {
    background-color: #2196f3;
    /* Background color when checked */
    color: #fff;
    /* Text color when checked */
    border-color: #2196f3;

    /* Border color when checked */
    &:hover {
        background-color: #2196f3;
        /* Background color when checked */
    }
}

.custom-radio {
    /* Hide the default radio button */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* Create a custom circular radio button */
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 50%;
    outline: none;
    /* Position the radio button relative to its container */
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.custom-radio:checked {
    /* Change border color when checked */
    border-color: #fff;

    /* Add inner circle when checked */
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        background-color: #fff;
        border-radius: 50%;
    }
}

.screenerBtn {
    &:hover {
        .icon {
            color: red;
        }
    }
}

.data-table--main {
    position: relative;
    display: grid;
    border: 1px solid var(--secondary-200);
    border-radius: 8px;
    overflow: hidden;
}

.mt-24 {
    margin-top: 24px;
}

.required {
    color: red;
}

.data-table--container {
    overflow: auto;
    width: 100%;
    scroll-behavior: smooth;
}

.position-relative {
    position: relative;
}

.d-grid {
    display: grid;
}

.ma-auto {
    margin: auto;
}

table {
    width: 100%;
}

.data-table--container thead {
    background-color: #f2f5f5;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.data-table--container thead th {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 12px 16px !important;
    white-space: nowrap;
    border-bottom: 1px solid #dadfe1 !important;
    border-top: 1px solid #dadfe1 !important;
}

tbody {
    border: 1px solid #dadfe1 !important;
}

.mb-28 {
    margin-bottom: 28px;
}

.mt-4 {
    margin-top: 4px;
}

.color-secondary-500 {
    color: #3e545e !important;
}

.text-md-regular {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.question-type-selection-item {
    width: 198px !important;
    height: auto !important;
}

.mr-16 {
    margin-right: 16px;
}

.mt-16 {
    margin-top: 16px;
}

.pa-16 {
    padding: 16px;
}

.round-12 {
    border-radius: 12px;
}

.border-2-secondary-200 {
    border: 2px solid #dadfe1;
}

.cursor-pointer {
    cursor: pointer !important;
}

.color-secondary {
    color: #1e4354 !important;
}

.text-heading-xxs {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.mr-4 {
    margin-right: 4px;
}

.mt--4 {
    margin-top: -4px;
}

.font-24 {
    font-size: 24px;
}

.question-section {
    background-color: #f2f5f5;
    border-radius: 8px;
    border: 1px solid #d5dbdd;
    padding: 20px;
    margin-top: 20px;
}

.flex-auto {
    flex: auto;
}

.d-flex {
    display: flex;
}

.color-secondary {
    color: #1e4354 !important;
}

.text-heading-sm {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.button-primary {
    background-color: #12a188;
    padding: 10px 14px;
    color: white;
    border-radius: 8px;
}

.input-box--container .input-box--input-padding-right {
    padding-right: 13px;
}

.input-box--container .input-box--input-padding-left {
    padding-left: 13px;
}
</style>
